import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import {graphql, useStaticQuery} from "gatsby";
import media from 'styled-media-query'


const GlobalStyles = createGlobalStyle`
  body {
    padding-top: 52px;
    
    ${media.lessThan('medium')`
      padding-top: 82px;
    `}
  }
  
  #gatsby-focus-wrapper {
    display: flex;
    flex-flow: column;
    min-height: calc(100vh - 75.2px);
  }
  
  main {
    flex: 1;
  }
  
  h1 + h2{
    margin-top: 0;
  }
  
  h1 + section {
    margin-top: calc(1.78rem - 3.56rem);
  }
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms;
  }
  
  .ReactModal__Overlay--after-open {
    opacity: 1;
  }
`

const GlobalTheme = ({children}: {children: any}) => {
  const data = useStaticQuery(query)

  const theme = {
    colors: data.site.siteMetadata.colorPalette
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyles/>
        {children}
      </ThemeProvider>
    </>
  )
}

const query = graphql`
  query GlobalThemeQuery {
    site {
      siteMetadata {
        colorPalette {
          primary
          light
          dark
          gray
          textOnPrimary
          textOnLight
          textOnDark
          textOnGray
          textOnWhite
          white
        }
      }
    }
  }
`

export default GlobalTheme
