import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import media from 'styled-media-query'

const FooterCore = ({className}) => {
  return (
    <footer className={className}>
      <div className={`${className}__inner`}>
      <p>&copy; 2020 LandBank</p>
      </div>
    </footer>
  )
}

const Footer = styled(FooterCore)`
  background: ${props => props.theme.colors.gray};
  color: ${props => props.theme.colors.textOnGray};
  font-size: 14px;
  padding: 16px 0;
  margin-top: 48px;
  
  &__inner {
    max-width: calc((1200px + 24px) * 1.1);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    
    ${media.lessThan('medium')`
      display: block;
    `}
  }
  
  a {
    color: ${props => props.theme.colors.textOnGray};
    text-decoration: none;
  }
  
  ul {
    display: flex;
    margin: 0 0 0 8px;
    
    ${media.lessThan('medium')`
      flex-wrap: wrap;
      margin-bottom: 8px;
      justify-content: center;
    `}
  }
  
  li {
    display: block;
    margin-right: 40px;
    margin-bottom: 0;
  }
  
  p {
    margin: 0 8px 0 0;
    text-align: center;
  }
`

export default Footer
