import React from 'react'
import Helmet from 'react-helmet'
import {SiteMetaQuery} from "../../../types/graphql-types";
import useSiteMetaQuery from "../../queries/siteMetaQuery";

type SeoProps = {
  data?: any
  title?: string
  description?: string
  image?: string
  slug?: string
  ogType?: string
  ogImageWidth?: string
  ogImageHeight?: string
  twitterCard?: string
  publishedTime?: Date
  modifiedTime?: Date
}

const Seo = ({ data, title, description, image, slug, ogType, twitterCard, ogImageWidth, ogImageHeight, publishedTime, modifiedTime }: SeoProps) => {
  const query: SiteMetaQuery = useSiteMetaQuery()
  
  const site = query.site.siteMetadata;

  if (!title)
    title = ((data || {}).frontmatter || {}).title
  if (!description)
    description = ((data || {}).frontmatter || {}).description
  if (!image)
    image = ((data || {}).frontmatter || {}).image
  if (!slug && slug !== '')
    slug = ((data || {}).frontmatter || {}).slug
  if (!ogType)
    ogType = ((data || {}).frontmatter || {}).ogType
  if (!twitterCard)
    twitterCard = ((data || {}).frontmatter || {}).twitterCard

  title = title ? `${title} - ${site.shortTitle}` : site.title
  description = description ? description : site.description
  image = description ? image : site.image
  const url = slug || slug === '' ? site.siteUrl + query.site.pathPrefix + slug : null
  ogType = ogType || 'website'
  twitterCard = twitterCard || 'summary'

  return (
    <Helmet htmlAttributes={{
      lang: site.language,
      prefix: 'og: http://ogp.me/ns#'
    }}>
      <title>{title}</title>
      { description && <meta name="description" content={description} /> }
      { url && <link href={url} rel="canonical"/> }

      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={title} />
      { description && <meta property="og:description" content={description} /> }
      { url && <meta property="og:url" content={url} /> }
      <meta property="og:site_name" content={site.title} />
      { image && <meta property="og:image" content={image} /> }
      { ogImageWidth && <meta property="og:image:width" content={ogImageWidth} /> }
      { ogImageHeight && <meta property="og:image:height" content={ogImageHeight} /> }
      { publishedTime && <meta property="article:published_time" content={publishedTime.toISOString()} /> }
      { modifiedTime && <meta property="article:modified_time" content={modifiedTime.toISOString()} /> }
      { image && <meta property="og:image:secure_url" content={image} /> }
      <meta name="twitter:card" content={twitterCard} />
      <meta name="twitter:title" content={title} />
      { description && <meta name="twitter:description" content={description} /> }
      { image && <meta name="twitter:image" content={image} /> }
    </Helmet>
  )
}

export default Seo
