import { graphql, useStaticQuery } from 'gatsby'
import {SiteMetaQuery} from '../../types/graphql-types'

const useSiteMetaQuery = () => {
  return useStaticQuery<SiteMetaQuery>(graphql`
    query SiteMeta {
      site {
        pathPrefix
        siteMetadata {
          title
          shortTitle
          description
          image
          siteUrl
          language
        }
      }
    }
  `)
}

export default useSiteMetaQuery
