import React from 'react'
import Footer from "../Footer/footer";
import Header from "../Header/header";
import GlobalTheme from "./global-theme";

const Layout = ({children, isHome}: {children: any, isHome?: boolean}) => {

  return (
    <GlobalTheme>

      <Header isHome={isHome} />
      <main>
        {children}
      </main>
      <Footer/>
    </GlobalTheme>
  )
}

export default Layout