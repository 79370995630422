import React from 'react'
import {graphql, Link, useStaticQuery} from 'gatsby'
import Image from 'gatsby-image'
import styled, { ThemeProvider } from 'styled-components'
import media from 'styled-media-query'

const StyledHeader = styled(({className, children}) => (
  <header className={className}><div className={`${className}__inner`}>{children}</div></header>
))`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.colors.primary};
  z-index: 98;
  
  &__inner {
    max-width: calc((1200px + 24px) * 1.1);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px;
    
    ${media.lessThan('medium')`
      display: block;
      padding: 8px 12px;
    `}
  }
`

const LogoWrapper = ({isHome, className, children}) => (
  isHome ? <h1 className={className}>{children}</h1>
    : <div className={className}>{children}</div>
)


const Logo = ({className, logo, isHome}) => (
  <LogoWrapper className={className} isHome={isHome}>
    <Link to="/">
      <Image fixed={logo} alt="LandBank" style={{display: 'block'}}/>
    </Link>
  </LogoWrapper>
)

const StyledLogo = styled(Logo)`
  margin: 0;
  ${media.lessThan('medium')`
    margin-bottom: 8px;
  `}

  a {
    transition: opacity 250ms;
    
    &:hover {
      opacity: .9;
    }
  }
  
  img {
    margin-bottom: 0;
  }
`

const Nav = ({className, children}) => (
  <nav className={className}>
    <ul>
      {children}
    </ul>
  </nav>
)

const StyledNav = styled(Nav)`
  ul {
    display: flex;
    margin: 0;
    
    ${media.lessThan('medium')`
      justify-content: flex-end;
    `}
  }
  
  li {
    margin: 0;
    padding: 0 28px;
    list-style: none;
    font-size: 16px;
    font-weight: 600;
    
    ${media.lessThan('medium')`
      font-size: 16px;
      padding: 0 8px;
    `}
    
    &:last-child {
      padding-right: 0;
    }
  }
`

const NavItem = ({className, children, to}) => (
  <li className={className}>
    <Link to={to}>{children}</Link>
  </li>
)

const StyledNavItem = styled(NavItem)`
  margin-right: 24px;
  padding: 0 12px;
  
  a {
    color: ${props => props.theme.colors.textOnPrimary};
    text-decoration: none;
    transition: opacity 250ms;
    
    &:hover {
      opacity: .9;
    }
  }
`

const Header = ({isHome}) => {
  const data = useStaticQuery(query)

  return (
    <StyledHeader>
      <StyledLogo logo={data.logo.childImageSharp.fixed} isHome={isHome ?? false}/>

        <StyledNav>
        </StyledNav>
    </StyledHeader>
  )
}

const query = graphql`
  query HeaderQuery {
    logo: file(relativePath: { eq: "logo-white.png" }) {
      childImageSharp {
        fixed(width: 160) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Header
